import { useController, UseControllerProps } from 'react-hook-form';
import FormField from '~app/components/FormField';
import TextArea from '~app/ui/TextArea';
import { AnalyzerFormValues } from '~env/models/forms';
import { t } from '~i18n';

interface AnalyzerTextareaFormFieldProps extends UseControllerProps<AnalyzerFormValues> {
  label: string;
  placeholder?: string;
}

const AnalyzerTextareaFormField: React.FC<AnalyzerTextareaFormFieldProps> = ({ label, placeholder, ...props }) => {
  const {
    field: { ref, ...fields },
    fieldState: { invalid, error },
  } = useController(props);

  return (
    <FormField label={t.get(label)} required={!!props.rules?.required}>
      <TextArea
        {...fields}
        placeholder={placeholder && t.get(placeholder)}
        isInvalid={invalid}
        errorMessage={t.get(error?.message || '')}
      />
    </FormField>
  );
};

export default AnalyzerTextareaFormField;
