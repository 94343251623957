const layer = {
  main: 1,
  nav: 10,
  header: 5,
  content: 10,
  loader: 100,
  helpPanel: 200,
};

export default layer;
