const space = [
  '0.25rem',
  '0.5rem',
  '0.75rem',
  '1rem',
  '1.5rem',
  '2rem',
  '2.5rem',
  '3.5rem',
  '4rem',
  '5rem',
  '8rem',
  '10rem',
  '13rem',
  '15rem',
  '17rem',
] as const;

export default space;
